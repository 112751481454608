import TeamGameCard from "../../shared/TeamGameCard";
import MatchInfo from "./MatchInfo";
import CompetitionStatistics from "./CompetitionStatistics";
import MarketStats from "./MarketCompetition";
import { useEffect, useState, useCallback } from "react";
import {
  fetchTopBettingMarkets,
  fetchSeasonStats,
} from "../../services/competition-service";

const InfoTab = ({ matches, shortname, seasonId, leagueName }) => {
  const [currentRound, setCurrentRound] = useState(1);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [topBettingMarkets, setTopBettingMarkets] = useState([]);
  const [competitionStatistics, setCompetitionStatistics] = useState([]);

  const processMatches = useCallback(() => {
    if (!matches || !matches.matches) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset to midnight for date comparisons

    // Filter matches for the current round that are not yet played and after today's date
    const filteredMatches = matches.matches.filter((match) => {
      const matchDate = new Date(match.date);
      matchDate.setHours(0, 0, 0, 0); // Reset to midnight for date-only comparison

      return (
        parseInt(match.round) === currentRound &&
        matchDate >= today &&
        match.played !== 1
      );
    });

    if (filteredMatches.length > 0) {
      setUpcomingMatches(filteredMatches);
    } else if (
      matches.matches.some((match) => parseInt(match.round) > currentRound)
    ) {
      setCurrentRound((prevRound) => prevRound + 1);
    }
  }, [matches, currentRound]);

  const fetchAdditionalData = useCallback(() => {
    if (shortname) {
      fetchTopBettingMarkets(shortname)
        .then((data) => setTopBettingMarkets(data || []))
        .catch((err) => console.error('Error fetching betting markets:', err));
    }

    if (seasonId) {
      fetchSeasonStats(seasonId)
        .then((data) => setCompetitionStatistics(data || []))
        .catch((err) => console.error('Error fetching statistics:', err));
    }
  }, [shortname, seasonId]);

  useEffect(() => {
    processMatches();
  }, [processMatches]);

  useEffect(() => {
    fetchAdditionalData();
  }, [fetchAdditionalData]);

  return (
    <div>
      <TeamGameCard team={{ matches: upcomingMatches }} />
      <div className="flex mx-1 my-1 overflow-x-auto snap-x snap-mandatory">
        {upcomingMatches.map((match, index) => (
          <MatchInfo
            key={index}
            matchId={match.id}
            homeTeam={match.homeTeam.name}
            guestTeam={match.guestTeam.name}
            homeLogoUrl={match.homeTeam.logoUrl}
            guestLogoUrl={match.guestTeam.logoUrl}
            time={match.time}
            date={new Date(match.date).toLocaleDateString('hr-HR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          />
        ))}
      </div>
      {seasonId === 651 || seasonId === 652 || seasonId === 653 ? (
        <div />
      ) : (
        <MarketStats topBettingMarkets={topBettingMarkets} />
      )}
      <CompetitionStatistics
        competitionStatistics={competitionStatistics}
        leagueName={leagueName}
        matches={matches}
        seasonId={seasonId}
      />
    </div>
  );
};

export default InfoTab;
