import React, { useState, useEffect, useMemo } from 'react';
import WebHeadingTitle from './webShared/WebHeadingTitle';
import Loader from '../shared/Loader';
import { useParams } from 'react-router-dom';
import { fetchLeagues } from '../services/competition-service';
import { TeamStandingsTable } from '../shared/TeamStandingsTable';
import GameCard from '../shared/GameCard';
import TeamLastFiveMatches from '../shared/TeamLastFiveMatches';
import TeamLastFiveH2HMatches from '../shared/TeamLastFiveH2HMatches';
import { useNavigate } from 'react-router-dom';
import HeadingTitle from '../components/layout/HeadingTitle';
import Lineups from '../components/formation/Lineups';
import NoFormationMessage from '../shared/NoFormationMessage';
import { getTeamAvgSeasonStats } from '../services/team-service';

import {
  fetchMatchDetails,
  fetchMatchEvents,
  getLastFiveH2HMatches,
  getTeamLastFive,
} from '../services/match-service';
import { fetchMatchFormations } from '../services/lineups-service';
import { getInjuredSuspended } from '../services/lineups-service';

const PrematchWeb = () => {
  const [loading, setLoading] = useState(true);
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchEvents, setMatchEvents] = useState(null);
  const [matchFormation, setMatchFormation] = useState(null);
  const [homeTeamLastFive, setHomeTeamLastFive] = useState([]);
  const [guestTeamLastFive, setGuestTeamLastFive] = useState([]);
  const [lastFiveH2H, setLastFiveH2H] = useState([]);
  const [injuredSuspendedHome, setInjuredSuspendedHome] = useState([]);
  const [injuredSuspendedGuest, setInjuredSuspendedGuest] = useState([]);
  const [leagueDetails, setLeagueDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { matchId } = useParams();
  const [betRadarMappings, setBetRadarMappings] = useState({});
  const [selectedTeam, setSelectedTeam] = useState('homeTeam');
  const [homeTeamStats, setHomeTeamStats] = useState({});
  const [guestTeamStats, setGuestTeamStats] = useState({});
  const navigate = useNavigate();
  const [homeFormation, setHomeFormation] = useState('');
  const [guestFormation, setGuestFormation] = useState('');
  const [homeOfficial, setHomeOfficial] = useState('');
  const [guestOfficial, setGuestOfficial] = useState('');

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  // Fetch the BetRadar mappings from the public/config directory
  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch('/config/betRadarIdsMapping.json');
        const data = await response.json();
        setBetRadarMappings(data);
      } catch (error) {
        console.error('Error fetching BetRadar mappings:', error);
      }
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (matchId && Object.keys(betRadarMappings).length > 0) {
      // Check if matchId exists in BetRadar ID mapping
      const mappedMatchId = betRadarMappings[matchId] || matchId;

      fetchMatchData(mappedMatchId);
    }
  }, [matchId, betRadarMappings]);

  const fetchMatchData = async (matchId) => {
    try {
      const data = await fetchMatchDetails(matchId);
      const events = await fetchMatchEvents(matchId);
      const formations = await fetchMatchFormations(matchId);
      setMatchDetails(data || {});
      setMatchEvents(events || []);
      setMatchFormation(formations || []);

      if (data && data.matches && data.matches.length > 0) {
        // Fetch injured/suspended players for both home and guest teams
        const homeTeamId = data.matches[0]?.homeTeam.sourceId;
        const guestTeamId = data.matches[0]?.guestTeam.sourceId;

        if (homeTeamId && guestTeamId) {
          const homeInjuredSuspended = await getInjuredSuspended(homeTeamId);
          const guestInjuredSuspended = await getInjuredSuspended(guestTeamId);

          setInjuredSuspendedHome(homeInjuredSuspended || []);
          setInjuredSuspendedGuest(guestInjuredSuspended || []);
        }
      }

      if (data.matches) {
        const homeTeamId = data.matches[0]?.homeTeam.id;
        const guestTeamId = data.matches[0]?.guestTeam.id;

        if (homeTeamId && guestTeamId) {
          const homeTeamLastFive = await getTeamLastFive(homeTeamId);
          const guestTeamLastFive = await getTeamLastFive(guestTeamId);
          const last5H2H = await getLastFiveH2HMatches(homeTeamId, guestTeamId);

          setHomeTeamLastFive(homeTeamLastFive || []);
          setGuestTeamLastFive(guestTeamLastFive || []);
          setLastFiveH2H(last5H2H || []);
        }
      }
    } catch (error) {
      console.error('Error fetching match data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Assuming matchDetails contains team IDs and season ID
    const fetchStats = async () => {
      const homeStats = await getTeamAvgSeasonStats(
        matchDetails.matches[0].homeTeam.id,
        matchDetails.matches[0].season_id
      );
      const guestStats = await getTeamAvgSeasonStats(
        matchDetails.matches[0].guestTeam.id,
        matchDetails.matches[0].season_id
      );
      setHomeTeamStats(homeStats);
      setGuestTeamStats(guestStats);
    };
    fetchStats();
  }, [matchDetails]);
  useEffect(() => {
    if (matchDetails?.matches && matchDetails.matches.length > 0) {
      const match = matchDetails.matches[0];
      const leagueShortname = match.league.shortname;

      const fetchLeague = async () => {
        try {
          const data = await fetchLeagues();
          let foundLeague = null;

          data.forEach((country) => {
            country.leagues.forEach((league) => {
              if (league.shortname === leagueShortname) {
                foundLeague = league;
              }
            });
          });

          if (foundLeague) {
            setLeagueDetails(foundLeague);
          }
          setIsLoading(false); // Set loading to false after fetching league details
        } catch (err) {
          console.error('Error fetching leagues:', err);
        }
      };

      fetchLeague();
    }
  }, [matchDetails]);

  useEffect(() => {
    if (!matchFormation) return;

    const homeData = extractFormation(matchFormation.homeTeamLineup || []);
    const guestData = extractFormation(matchFormation.guestTeamLineup || []);

    setHomeFormation(homeData.formation);
    setHomeOfficial(homeData.official);
    setGuestFormation(guestData.formation);
    setGuestOfficial(guestData.official);
    setIsLoading(false);
  }, [matchFormation]);

  const extractFormation = (teamLineup) => {
    for (let i = teamLineup.length - 1; i >= 0; i--) {
      if (teamLineup[i]?.formationUsed !== '') {
        return {
          formation: teamLineup[i].formationUsed,
          official: teamLineup[i].teamOfficial,
        };
      }
    }
    return { formation: '', official: '' };
  };

  const extractUniquePlayers = (players, isStarting) => {
    const uniquePlayerIds = new Set();
    return players.filter((player) => {
      const isValidPlayer =
        !uniquePlayerIds.has(player?.playerId) &&
        player?.isStarting === isStarting &&
        (isStarting
          ? player.formationPosition !== null
          : player.formationPosition === null);

      if (isValidPlayer) {
        uniquePlayerIds.add(player.playerId);
        return true;
      }
      return false;
    });
  };
  const homePlayers = useMemo(
    () => extractUniquePlayers(matchFormation?.homeTeam || [], true),
    [matchFormation]
  );
  const guestPlayers = useMemo(
    () => extractUniquePlayers(matchFormation?.guestTeam || [], true),
    [matchFormation]
  );
  const homeSubs = useMemo(
    () => extractUniquePlayers(matchFormation?.homeTeam || [], false),
    [matchFormation]
  );
  const guestSubs = useMemo(
    () => extractUniquePlayers(matchFormation?.guestTeam || [], false),
    [matchFormation]
  );

  function formatAndSortPlayers(players, formation) {
    const formationMap = {
      442: [3, 6, 5, 2, 11, 8, 4, 7, 9, 10],
      41212: [3, 6, 5, 2, 4, 11, 7, 8, 9, 10],
      433: [3, 6, 5, 2, 8, 4, 7, 11, 9, 10],
      451: [3, 6, 5, 2, 11, 8, 10, 4, 7, 9],
      4411: [3, 6, 5, 2, 11, 8, 4, 7, 10, 9],
      4141: [3, 6, 5, 2, 4, 11, 10, 8, 7, 9],
      4231: [3, 6, 5, 2, 4, 8, 11, 10, 7, 9],
      4321: [3, 6, 5, 2, 7, 4, 8, 11, 10, 9],
      532: [3, 4, 5, 6, 2, 11, 8, 7, 9, 10],
      541: [3, 4, 5, 6, 2, 11, 10, 8, 7, 9],
      352: [4, 5, 6, 3, 8, 11, 7, 2, 9, 10],
      343: [4, 5, 6, 3, 8, 7, 2, 11, 9, 10],
      4222: [3, 6, 5, 2, 8, 4, 11, 7, 9, 10],
      3511: [4, 5, 6, 3, 8, 11, 7, 2, 10, 9],
      3421: [4, 5, 6, 3, 8, 7, 2, 11, 10, 9],
      3412: [4, 5, 6, 3, 8, 7, 2, 9, 11, 10],
      3142: [6, 5, 4, 8, 3, 11, 7, 2, 10, 9],
      '343d': [4, 5, 6, 8, 3, 2, 7, 11, 9, 10],
      4132: [3, 6, 5, 2, 4, 11, 8, 7, 10, 9],
      4240: [3, 6, 5, 2, 8, 4, 11, 10, 9, 7],
      4312: [3, 6, 5, 2, 11, 4, 7, 8, 10, 9],
      3241: [4, 5, 6, 3, 2, 11, 8, 7, 10, 9],
      3331: [4, 5, 6, 3, 8, 2, 11, 7, 10, 9],
      // Add more formations as needed
    };

    const formationIndices = formationMap[formation];
    if (formationIndices) {
      return players.sort(
        (a, b) =>
          formationIndices.indexOf(a.formationPosition) -
          formationIndices.indexOf(b.formationPosition)
      );
    }
    return players;
  }
  const sortedHomePlayers = useMemo(
    () => formatAndSortPlayers(homePlayers, homeFormation),
    [homePlayers, homeFormation]
  );
  const sortedGuestPlayers = useMemo(
    () => formatAndSortPlayers(guestPlayers, guestFormation),
    [guestPlayers, guestFormation]
  );

  const countPlayerEvents = (events, playerId) => {
    return events.reduce((counts, event) => {
      if (event.playerMainSourceId === playerId) {
        counts[event.type] = (counts[event.type] || 0) + 1;
      }
      return counts;
    }, {});
  };
  const filterPlayersByDate = (players) => {
    const today = new Date();
    return players.filter((player) => {
      const endDate = player.endDate || player.expectedEndDate;
      return !endDate || new Date(endDate) >= today;
    });
  };

  const filteredInjuredSuspendedHome =
    filterPlayersByDate(injuredSuspendedHome);
  const filteredInjuredSuspendedGuest = filterPlayersByDate(
    injuredSuspendedGuest
  );

  const getPlayerShirtNumber = (playerId, players) => {
    const player = players.find((p) => p.playerId === playerId);
    return player ? player.shirtNumber : null;
  };

  const getIcon = (type) => {
    switch (type) {
      case 'INJURY':
        return '/img/svg/injury.svg';
      case 'suspension':
        return '/img/svg/yellowCard.svg';
      default:
        return null; // Fallback icon
    }
  };

  const PlayerList = ({ players, events, isHome }) => (
    <table className="w-full border border-bg-divider">
      <tbody>
        {players.map((player, index) => (
          <tr key={index} className="h-12">
            <td className="border order-bg-divider relative">
              <div className="flex items-center pl-5 relative">
                <div className="relative">
                  <img
                    src={
                      isHome ? '/img/svg/kitHome.svg' : '/img/svg/kitAway.svg'
                    }
                    alt="Kit"
                    className="object-cover h-[22px] w-[24px] z-10"
                  />
                  <div className="absolute top-3 left-3 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center font-light text-white text-lineupsText">
                    {player?.shirtNumber}
                  </div>
                </div>
                <div className="ml-2 items-center">
                  <div className="flex text-lineupsText pl-1">
                    {player?.playerName}

                    <div className="flex ml-1">
                      {events.some(
                        (event) =>
                          event?.playerMainSourceId === player?.playerId
                      ) && (
                        <EventIndicatorSubs player={player} events={events} />
                      )}
                    </div>
                  </div>
                  {events
                    .filter(
                      (event) => event?.playerOtherSourceId === player?.playerId
                    )
                    .map((event, index) => (
                      <div key={index} className="flex items-center ml-1">
                        <img
                          src="/img/svg/subs.svg"
                          alt="Substitution"
                          className="object-cover rounded-full h-4 w-4 mr-1"
                        />
                        <p className="text-xs">
                          <span className="text-green-600 mr-1">
                            {event?.minute}'
                          </span>
                          <span className="text-textSecondary">
                            {event?.playerMain}
                          </span>
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  const EventIndicatorSubs = ({ player, events }) => {
    const eventCounts = useMemo(
      () => countPlayerEvents(events, player?.playerId),
      [events, player?.playerId]
    );
    const eventIcons = {
      1: '/img/svg/goal.svg',
      2: '/img/svg/yellowCard.svg',
      3: '/img/svg/ownGoal.svg',
      4: '/img/svg/redCard.svg',
      8: '/img/svg/secondYellow.svg',
    };

    return (
      <div>
        {Object.entries(eventCounts).map(([eventType, count]) => (
          <React.Fragment key={eventType}>
            {Array.from({ length: count }).map((_, index) => (
              <img
                key={index}
                src={eventIcons[eventType]}
                width={12}
                height={14}
                alt={eventType}
                className="inline mb-1"
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  };

  if (
    loading ||
    !matchDetails ||
    !matchDetails.matches ||
    matchDetails.matches.length === 0 ||
    isLoading
  ) {
    return <Loader />; // Display loader while fetching data
  }

  const match = matchDetails.matches[0];

  const formatDate = (dateString, timeString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(`${dateString}T${timeString}`);
    const formattedDate = date.toLocaleDateString('hr-HR', options);
    const formattedTime = date.toLocaleTimeString('hr-HR', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const mainOfficial = JSON.parse(
    matchDetails.matches[0].matchOfficialMain
  ).filter((official) => official.type === 'Main');
  const mainOfficialNames = mainOfficial
    .map((official) => `${official.firstName} ${official.lastName}`)
    .join(', ');

  const gameInfo = [
    { type: 'Lokacija', gamelocation: matchDetails.matches[0].location },
    {
      type: 'Datum i vrijeme',
      gameDate: formatDate(
        matchDetails.matches[0].date,
        matchDetails.matches[0].time
      ),
    },
    { type: 'Sudac', gameJury: mainOfficialNames },
    // { type: "Prosjek kartona", cardsAvg: { red: 1.2, yellow: 3.4 } },
  ];

  function getScoreColor(score, isHomeTeam) {
    const [homeScore, awayScore] = score.split(':').map(Number);
    if (isHomeTeam) {
      if (homeScore > awayScore) {
        return 'bg-successGreen'; // Green for home team win
      } else if (homeScore < awayScore) {
        return 'bg-redContainer'; // Red for home team loss
      } else {
        return 'bg-textSecondary'; // Grey for draw
      }
    } else {
      if (homeScore > awayScore) {
        return 'bg-redContainer'; // Red for away team loss
      } else if (homeScore < awayScore) {
        return 'bg-successGreen'; // Green for away team win
      } else {
        return 'bg-textSecondary'; // Grey for draw
      }
    }
  }

  function getUnderlineColor(score, isHomeTeam) {
    const [homeScore, awayScore] = score.split(':').map(Number);
    if (isHomeTeam) {
      if (homeScore > awayScore) {
        return 'border-b-2 border-successGreen'; // Green underline for home team win
      } else if (homeScore < awayScore) {
        return 'border-b-2 border-redContainer'; // Red underline for home team loss
      } else {
        return 'border-b-2 border-textSecondary'; // Grey underline for draw
      }
    } else {
      if (homeScore > awayScore) {
        return 'border-b-2 border-redContainer'; // Red underline for away team loss
      } else if (homeScore < awayScore) {
        return 'border-b-2 border-successGreen'; // Green underline for away team win
      } else {
        return 'border-b-2 border-textSecondary'; // Grey underline for draw
      }
    }
  }
  const statistics = [
    {
      type: 'Zabijeni golovi',
      homeValue: homeTeamStats.scoredGoals,
      guestValue: guestTeamStats.scoredGoals,
    },
    {
      type: 'Očekivani golovi',
      homeValue: homeTeamStats.expectedGoals,
      guestValue: guestTeamStats.expectedGoals,
    },
    {
      type: 'Primljeni golovi',
      homeValue: homeTeamStats.concededGoals,
      guestValue: guestTeamStats.concededGoals,
    },
    {
      type: 'Očekivani primljeni golovi',
      homeValue: homeTeamStats.expectedConcededGoals,
      guestValue: guestTeamStats.expectedConcededGoals,
    },
    {
      type: 'Udarci',
      homeValue: homeTeamStats.totalShoots,
      guestValue: guestTeamStats.totalShoots,
    },
    {
      type: 'Udarci u okvir',
      homeValue: homeTeamStats.shootsOnTarget,
      guestValue: guestTeamStats.shootsOnTarget,
    },
    {
      type: 'Korneri',
      homeValue: homeTeamStats.cornerKicks,
      guestValue: guestTeamStats.cornerKicks,
    },
    {
      type: 'Žuti karton',
      homeValue: homeTeamStats.yellowCards,
      guestValue: guestTeamStats.yellowCards,
    },
    {
      type: 'Crveni karton',
      homeValue: homeTeamStats.redCards,
      guestValue: guestTeamStats.redCards,
    },
  ];

  function navigateToMatch(matchId) {
    navigate('/match/' + matchId);
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container mx-auto flex-grow">
            <WebHeadingTitle message="Detalji" />
            <div className="flex flex-wrap">
              <div className="flex-1 pr-2 flex flex-col">
                {homeTeamLastFive.length > 0 &&
                  guestTeamLastFive.length > 0 && (
                    <div>
                      <HeadingTitle title={'Posljednjih 5 utakmica'} />
                      {/* component for the last 5 games */}
                      <div className="flex justify-center p-5 space-x-10">
                        <div className="flex-1 flex flex-col items-center">
                          <h2 className="font-medium text-center text-scoreText text-textSecondary pb-2">
                            {matchDetails.matches[0].homeTeam.name}
                          </h2>
                          <table className="">
                            <tbody className="">
                              {homeTeamLastFive.map(
                                (homeTeamMatches, index) => (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      navigateToMatch(homeTeamMatches.id)
                                    }
                                  >
                                    <td className="px-2 py-1">
                                      <img
                                        src={homeTeamMatches.homeTeam.logoUrl}
                                        alt="homeTeamLogoUrl"
                                        className="object-cover rounded-full h-[24px] w-[24px]"
                                      />
                                    </td>
                                    <td className="pb-2 px-2 py-2 relative">
                                      <div
                                        className={`m-1 rounded h-5 w-8 flex justify-center items-center ${getScoreColor(
                                          homeTeamMatches.homeTeam.scored +
                                            ':' +
                                            homeTeamMatches.guestTeam.scored,
                                          homeTeamMatches.homeTeam.id ===
                                            matchDetails.matches[0].homeTeam.id
                                        )}`}
                                      >
                                        <span className="text-white text-scoreText font-light">
                                          {homeTeamMatches.homeTeam.scored +
                                            ':' +
                                            homeTeamMatches.guestTeam.scored}
                                        </span>
                                      </div>
                                      {index === 4 && (
                                        <div
                                          className={`absolute bottom-[5px] left-[21px] w-3 rounded ${getUnderlineColor(
                                            homeTeamMatches.homeTeam.scored +
                                              ':' +
                                              homeTeamMatches.guestTeam.scored,
                                            homeTeamMatches.homeTeam.id ===
                                              matchDetails.matches[0].homeTeam
                                                .id
                                          )}`}
                                        ></div>
                                      )}
                                    </td>

                                    <td className="px-2 py-1">
                                      <img
                                        src={homeTeamMatches.guestTeam.logoUrl}
                                        alt="guestTeamLogoUrl"
                                        className="object-cover rounded-full h-[24px] w-[24px]"
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="flex-1 flex flex-col items-center pl-2">
                          <h2 className="font-medium text-center text-scoreText text-textSecondary pb-2">
                            {matchDetails.matches[0].guestTeam.name}
                          </h2>
                          <table>
                            <tbody>
                              {guestTeamLastFive.map(
                                (guestTeamMatches, index) => (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      navigateToMatch(guestTeamMatches.id)
                                    }
                                  >
                                    <td className="px-2 py-1">
                                      <img
                                        src={guestTeamMatches.homeTeam.logoUrl}
                                        alt="homeTeamLogoUrl"
                                        className="object-cover rounded-full h-[24px] w-[24px]"
                                      />
                                    </td>
                                    <td className="pb-2 px-2 py-2 relative">
                                      <div
                                        className={`m-1 rounded h-5 w-8 flex justify-center items-center ${getScoreColor(
                                          guestTeamMatches.homeTeam.scored +
                                            ':' +
                                            guestTeamMatches.guestTeam.scored,
                                          guestTeamMatches.homeTeam.id ===
                                            matchDetails.matches[0].guestTeam.id
                                        )}`}
                                      >
                                        <span className="text-white  text-scoreText font-light">
                                          {guestTeamMatches.homeTeam.scored +
                                            ':' +
                                            guestTeamMatches.guestTeam.scored}
                                        </span>
                                      </div>
                                      {index === 4 && (
                                        <div
                                          className={`absolute bottom-[5px] left-[21px] w-3 rounded ${getUnderlineColor(
                                            guestTeamMatches.homeTeam.scored +
                                              ':' +
                                              guestTeamMatches.guestTeam.scored,
                                            guestTeamMatches.homeTeam.id ===
                                              matchDetails.matches[0].guestTeam
                                                .id
                                          )}`}
                                        ></div>
                                      )}
                                    </td>
                                    <td className="px-2 py-1">
                                      <img
                                        src={guestTeamMatches.guestTeam.logoUrl}
                                        alt="guestTeamLogoUrl"
                                        className="object-cover rounded-full h-[24px] w-[24px]"
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="flex-1 ">
                {statistics.length > 0 && (
                  <div>
                    <HeadingTitle
                      title={`Statistika ${matchDetails.matches[0].league.name}`}
                    />
                    <div className="flex pl-3 pr-3 pt-2 ">
                      <table className="w-full">
                        <tbody>
                          {statistics.map((stat, index) => {
                            const homeStyle =
                              stat.homeValue > stat.guestValue
                                ? 'bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-extralight h-[24px] font-sm'
                                : 'font-normal text-black text-scoreText items-center justify-center';
                            const guestStyle =
                              stat.guestValue > stat.homeValue
                                ? 'bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-extralight h-[24px]'
                                : 'font-normal text-black text-scoreText items-center justify-center';

                            return (
                              <tr key={index} className="text-center">
                                <td className="w-[28px] h-[26px] pb-3">
                                  <div className={homeStyle}>
                                    {stat.homeValue}
                                  </div>
                                </td>
                                <td className="pb-3 text-scoreText">
                                  {stat.type}
                                </td>
                                <td className="w-[28px] h-[26px] pb-3">
                                  <div className={guestStyle}>
                                    {stat.guestValue}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1 px-5">
                {gameInfo.length > 0 && (
                  <div>
                    <HeadingTitle title={'Informacije o utakmici'} />
                    <div className="flex">
                      <table className="w-full">
                        <tbody>
                          {gameInfo.map((info, index) => (
                            <React.Fragment key={index}>
                              {/* Only render if at least one of gamelocation, gameDate, or gameJury is present */}
                              {(info.gamelocation ||
                                info.gameDate ||
                                info.gameJury ||
                                (info.cardsAvg &&
                                  (info.cardsAvg.yellow > 0 ||
                                    info.cardsAvg.red > 0))) && (
                                <tr>
                                  <td>
                                    <div className="flex-1 p-2 font-medium text-scoreText">
                                      {info.type}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-1 text-right pr-2 font-normal text-scoreText">
                                      <div className="flex items-center justify-end">
                                        {info.gamelocation && (
                                          <p className="">
                                            {info.gamelocation}
                                          </p>
                                        )}
                                        {info.gameDate && (
                                          <p className="">{info.gameDate}</p>
                                        )}
                                        {info.gameJury && (
                                          <p className="">{info.gameJury}</p>
                                        )}
                                        {info.cardsAvg && (
                                          <React.Fragment>
                                            {info.cardsAvg.yellow > 0 && (
                                              <React.Fragment>
                                                <img
                                                  src="/img/svg/yellowCard.svg"
                                                  alt="Yellow Card"
                                                  className="h-6 mr-1 h-[15px]"
                                                />
                                                <span className="pr-4">
                                                  {info.cardsAvg.yellow}
                                                </span>
                                              </React.Fragment>
                                            )}
                                            {info.cardsAvg.red > 0 && (
                                              <React.Fragment>
                                                <img
                                                  src="/img/svg/redCard.svg"
                                                  alt="Red Card"
                                                  className="h-6 mr-1 h-[15px]"
                                                />
                                                <span>{info.cardsAvg.red}</span>
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container mx-auto  flex-grow">
            <WebHeadingTitle message="H2H" />
            <div className="flex flex-wrap">
              <div className="flex-1 pr-2 flex flex-col">
                {leagueDetails && leagueDetails.isCup === 0 && (
                  <TeamStandingsTable
                    leagueShortname={match.league.shortname}
                    leagueName={match.league.name}
                    homeTeam={match.homeTeam}
                    guestTeam={match.guestTeam}
                    matchId={match.id}
                  />
                )}
                <GameCard
                  homeTeam={match.homeTeam}
                  guestTeam={match.guestTeam}
                  date={match.date}
                  time={match.time}
                  matchId={match.id}
                  shortname={match.league.shortname}
                />
              </div>

              <div className="flex-1 p-2 pt-10">
                <TeamLastFiveMatches
                  homeTeam={match.homeTeam}
                  guestTeam={match.guestTeam}
                  homeTeamLastFive={homeTeamLastFive}
                  guestTeamLastFive={guestTeamLastFive}
                  selectedTeam={selectedTeam}
                  onTeamSelect={handleTeamSelect}
                />
              </div>
              <div className="flex-1 p-2 pt-10">
                <TeamLastFiveH2HMatches
                  lastFiveH2HMatches={lastFiveH2H}
                  homeTeam={match.homeTeam}
                  guestTeam={match.guestTeam}
                  selectedTeam={selectedTeam}
                  onTeamSelect={handleTeamSelect}
                />
              </div>
            </div>
          </div>
          <div className="container mx-auto  flex-grow">
            <WebHeadingTitle message="Postava" />
            {matchFormation.length > 0 ? (
              <div className="flex flex-wrap">
                <div className="">
                  <Lineups
                    homeTeamPlayers={sortedHomePlayers}
                    guestTeamPlayers={sortedGuestPlayers}
                    homeFormation={homeFormation}
                    guestFormation={guestFormation}
                    isLoading={isLoading}
                    matchEvents={matchEvents}
                  />
                </div>
                <div>
                  <div className="flex">
                    <div className="flex-1">
                      <table className="w-full border border-bg-divider">
                        <tbody>
                          <tr>
                            <td className="border border-bg-divider p-2">
                              <div className="flex p-2">
                                <img
                                  src={
                                    matchDetails?.matches[0].homeTeam.logoUrl
                                  }
                                  alt={matchDetails?.matches[0].homeTeam.name}
                                  className="object-cover rounded-full h-[24px] w-[24px]"
                                />
                                <h2 className="uppercase pl-2 text-textSecondary text-scoreText pt-0.5">
                                  {matchDetails?.matches[0].homeTeam.name}
                                </h2>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bg-gray-100">
                              <div className="flex items-center pl-5 p-1">
                                <img
                                  src="/img/svg/kitHome.svg"
                                  alt="Home kit"
                                  className="object-cover h-[22px] w-[24px] z-10"
                                />
                                <div className="ml-2">
                                  <p className="text-lineupsText ">
                                    {homeOfficial}
                                  </p>
                                  <p className="text-xs text-textSecondary">
                                    Trener
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="flex-1">
                      <div>
                        <table className="w-full border border-bg-divider">
                          <tbody>
                            <tr>
                              <td className="border order-bg-divider p-2">
                                <div className="flex p-2">
                                  <img
                                    src={
                                      matchDetails?.matches[0].guestTeam.logoUrl
                                    }
                                    alt={
                                      matchDetails?.matches[0].guestTeam.name
                                    }
                                    className="object-cover rounded-full h-[24px] w-[24px]"
                                  />
                                  <h2 className="uppercase pl-2 text-textSecondary text-scoreText pt-0.5">
                                    {matchDetails?.matches[0].guestTeam.name}
                                  </h2>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-gray-100">
                                <div className="flex items-center pl-5 p-1">
                                  <img
                                    src="/img/svg/kitAway.svg"
                                    alt="Away kit"
                                    className="oobject-cover  h-[22px] w-[24px] z-10"
                                  />
                                  <div className="ml-2">
                                    <p className="text-lineupsText">
                                      {guestOfficial}
                                    </p>
                                    <p className="text-xs text-textSecondary">
                                      Trener
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div>
                    <HeadingTitle title={'Rezervni igrači'} />
                    <div className="flex">
                      <div className="flex-1">
                        <PlayerList
                          players={homeSubs}
                          events={matchEvents?.events}
                          isHome={1}
                        />
                      </div>
                      <div className="flex-1">
                        <PlayerList
                          players={guestSubs}
                          events={matchEvents?.events}
                          isHome={0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    {filteredInjuredSuspendedHome.length > 0 ||
                    filteredInjuredSuspendedGuest.length > 0 ? (
                      <div className="">
                        <HeadingTitle title="Ozljeđeni i kažnjeni igrači" />
                        <div className="flex">
                          <div className="flex-1">
                            <table className="w-full border border-bg-divider">
                              <tbody>
                                {filteredInjuredSuspendedHome.map(
                                  (player, index) => {
                                    const shirtNumber = getPlayerShirtNumber(
                                      player.playerId,
                                      [...homePlayers, ...homeSubs]
                                    );
                                    return (
                                      <tr key={index}>
                                        <td className="border border-gray-300 relative h-12">
                                          <div className="flex items-center pl-5 p-1 relative">
                                            <div className="relative">
                                              <img
                                                src="/img/svg/kitHome.svg"
                                                alt="Home kit"
                                                className="object-cover h-[22px] w-[24px] z-10"
                                              />
                                              <div className="absolute top-3 left-3 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center font-light text-white text-xs">
                                                {shirtNumber}
                                              </div>
                                            </div>
                                            <div className="ml-2 flex ">
                                              <p className="text-lineupsText ">
                                                {player?.name}
                                              </p>
                                              {player.type && (
                                                <img
                                                  src={getIcon(player.type)}
                                                  alt={player.type}
                                                  className="ml-1 h-[16px] w-[16px]"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="flex-1">
                            <table className="w-full border border-bg-divider">
                              <tbody>
                                {filteredInjuredSuspendedGuest.map(
                                  (player, index) => {
                                    const shirtNumber = getPlayerShirtNumber(
                                      player.playerId,
                                      [...guestPlayers, ...guestSubs]
                                    );
                                    return (
                                      <tr key={index}>
                                        <td className="border border-gray-300 relative h-12">
                                          <div className="flex items-center pl-5 p-1 relative">
                                            <div className="relative">
                                              <img
                                                src="/img/svg/kitAway.svg"
                                                alt="Away kit"
                                                className="object-cover h-[22px] w-[24px] z-10"
                                              />
                                              <div className="absolute top-3 left-3 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center font-light text-white text-xs">
                                                {shirtNumber}
                                              </div>
                                            </div>
                                            <div className="ml-2 flex ">
                                              <p className="text-lineupsText">
                                                {player?.name}
                                              </p>
                                              {player.type && (
                                                <img
                                                  src={getIcon(player.type)}
                                                  alt={player.type}
                                                  className="ml-1 h-[16px] w-[16px]"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                  <NoFormationMessage />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PrematchWeb;
