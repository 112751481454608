import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate, useNavigation } from '../services/shared-service';

function GameCard({ homeTeam, guestTeam, date, time, matchId, shortname }) {
  const formattedDate = formatDate(date);
  const navigateTo = useNavigation();

  return (
    <div>
      {homeTeam && guestTeam && date && time && (
        <div className="container grid grid-cols-3 gap-4 mt-3 mb-1 items-center px-4 sm:px-6 md:px-10">
          {/* Left Side */}
          <div className="flex flex-col items-center text-center">
            <div
              className="cursor-pointer"
              onClick={() =>
                navigateTo(`/team/${homeTeam.id}?matchId=${matchId}`)
              }
            >
              <img
                src={homeTeam.logoUrl}
                alt={homeTeam.name}
                className="object-cover rounded-full h-[24px] w-[24px]"
              />
            </div>
            <p className="font-medium text-black text-lineupsText mt-1 truncate max-w-[100px]">
              {homeTeam.name}
            </p>
          </div>

          {/* Middle */}

          <Link
            to={{
              pathname: `/competition/${shortname}`,
            }}
            state={{ from: 'gameCard' }}
            className="flex items-center"
          >
            <div className="container flex flex-col items-center">
              <p className="font-normal text-xs text-center">
                Sljedeća utakmica
              </p>

              <p className="font-bold text-black text-base">{time}h</p>
              <p className="font-medium text-lineupsText text-textSecondary">
                {formattedDate}
              </p>
            </div>
          </Link>

          {/* Right Side */}
          <div className="flex flex-col items-center text-center">
            <div
              className="cursor-pointer"
              onClick={() =>
                navigateTo(`/team/${guestTeam.id}?matchId=${matchId}`)
              }
            >
              <img
                src={guestTeam.logoUrl}
                alt={guestTeam.name}
                className="object-cover rounded-full h-[24px] w-[24px]"
              />
            </div>

            <p className="font-medium text-black text-lineupsText mt-1 truncate max-w-[100px]">
              {guestTeam.name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default GameCard;
