import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchLeagueMatches,
  fetchLeagues,
  fetchLeagueScorers,
} from '../services/competition-service';
import { useParams } from 'react-router-dom';
import Loader from '../shared/Loader';
import InfoTab from '../components/competition/CompetitionInfoTab';
import CompetitionMatches from '../shared/CompetitionMatches';
import CompetitionPlayerStatistics from '../components/competition/CompetitionPlayerStatistics';
import { TeamStandingsTable } from '../shared/TeamStandingsTable';
import { Tabs } from '../shared/Tabs';

const Competition = () => {
  const [activeTab, setActiveTab] = useState('info');
  const [leagueDetails, setLeagueDetails] = useState(null);
  const [leagueMatches, setLeagueMatches] = useState(null);
  const [leagueScorers, setLeagueScorers] = useState(null);
  const [loading, setLoading] = useState(true); // Initial loading state
  const { shortname } = useParams();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  /**
   * ## init
   * @description Fetch leagues, find id by shortname and fetch matches and scores
   * @type {(function(): Promise<void>)|*}
   */
  const init = useCallback(async () => {
    if (!shortname) return;
    setLoading(true); // Start loading when we begin fetching data
    try {
      const leaguesData = await fetchLeagues();
      const league = leaguesData
        .flatMap(({ leagues }) => leagues)
        .find((league) => league.shortname === shortname);

      if (league) {
        await Promise.all([
          fetchMatches(league.id),
          fetchScorers(league.id),
        ]);
        setLeagueDetails(league);
      }
    } catch (err) {
      console.error('Error fetching leagues:', err);
    } finally {
      setLoading(false); // Set loading to false once all data is fetched
    }
  }, [shortname]);

  useEffect(() => {
    init();
  }, [init]);

  const fetchMatches = async (leagueId) => {
    try {
      const data = await fetchLeagueMatches(leagueId);
      setLeagueMatches(data || []);
    } catch (err) {
      console.error('Error fetching matches:', err);
    }
  };

  const fetchScorers = async (leagueId) => {
    try {
      const data = await fetchLeagueScorers(leagueId);
      setLeagueScorers(data);
    } catch (err) {
      console.error('Error fetching scorers:', err);
    }
  };

  const tabs = [
    {
      label: 'Info',
      isActive: activeTab === 'info',
      onClick: () => handleTabClick('info'),
    },
    {
      label: 'Tablica',
      isActive: activeTab === 'table',
      onClick: () => handleTabClick('table'),
    },
    {
      label: 'Utakmice',
      isActive: activeTab === 'games',
      onClick: () => handleTabClick('games'),
    },
    {
      label: 'Statistika',
      isActive: activeTab === 'statistics',
      onClick: () => handleTabClick('statistics'),
    },
  ];

  return (
    <div>
      <div className="p-3 bg-white ">
        <Tabs tabs={tabs} />
      </div>
      <div>
        {loading ? (
          <Loader /> // Show loader while data is loading
        ) : (
          <>
            {activeTab === 'info' && leagueDetails && leagueMatches && (
              <InfoTab
                matches={leagueMatches}
                shortname={shortname}
                seasonId={leagueDetails.seasonId}
                leagueName={leagueDetails.name}
                totalMatches={leagueMatches}
              />
            )}
            {activeTab === 'table' && leagueDetails && (
              <TeamStandingsTable
                leagueShortname={leagueDetails.shortname}
                leagueName={leagueDetails.subLeagueName}
                homeTeam=""
                guestTeam=""
              />
            )}
            {activeTab === 'games' && leagueMatches && (
              <CompetitionMatches leagueMatches={leagueMatches} />
            )}
            {activeTab === 'statistics' && leagueScorers && (
              <CompetitionPlayerStatistics leagueScorers={leagueScorers} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Competition;
