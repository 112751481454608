import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import ErrorPage from './pages/ErrorPage';
import Landing from './pages/Landing';
import Results from './pages/Results';
import PrematchDetails from './pages/PrematchDetails';
import Standings from './pages/Standings';
import MatchDetails from './pages/MatchDetails';
import TeamPage from './pages/TeamPage';
import Competition from './pages/Competition';
import Player from './pages/Player';
import CompetitionWeb from './web/CompetitionWeb';
import { Navigation } from './shared/Navigation';
import PrematchWeb from './web/PrematchWeb';
import TeamWeb from './web/TeamWeb';

const routes = [
  {
    path: '/',
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/standings',
    element: (
      <Navigation>
        <Standings />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/prematch/:matchId',
    element: <PrematchDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/match/:matchId',
    element: (
      <Navigation>
        <MatchDetails />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/results',
    element: (
      <Navigation>
        <Results />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/team/:teamId',
    element: (
      <Navigation>
        <TeamPage />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/competition/:shortname',
    element: (
      <Navigation>
        <Competition />
      </Navigation>
    ),

    errorElement: <ErrorPage />,
  },
  {
    path: '/player/:playerId',
    element: (
      <Navigation>
        <Player />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/web/competition/:shortname',
    element: (
      <Navigation>
        <CompetitionWeb />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/web/team/:teamId',
    element: (
      <Navigation>
        <TeamWeb />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/web/prematch/:matchId',
    element: (
      <Navigation>
        <PrematchWeb />
      </Navigation>
    ),
    errorElement: <ErrorPage />,
  },
];

const router = createBrowserRouter(routes, {
  basename: '/',
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
