import HeadingTitle from '../components/layout/HeadingTitle';
import { useNavigation } from '../services/shared-service';
import { TeamFlagBadge } from './TeamFlagBadge';

const CompetitionMatches = ({ leagueMatches }) => {
  const today = new Date();
  const navigateTo = useNavigation();

  // Filter past and future matches
  // Filter past and future matches
  const pastMatches = leagueMatches.matches.filter(
    (match) => new Date(match.date) <= today && match.played === 1
  );

  const futureMatches = leagueMatches.matches.filter((match) => {
    // Include matches that are today or in the future, and haven't been played yet
    return new Date(match.date) >= today || match.played === 0;
  });

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('hr-HR', { day: 'numeric', month: 'long' });
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-center">
        {/* Your buttons for filtering view can go here if needed */}
      </div>
      {futureMatches.length > 0 && (
        <div>
          <HeadingTitle title={'Nadolazeće utakmice'} />
          {futureMatches.length > 0 ? (
            futureMatches.map((match, index) => (
              <div
                key={index}
                className={`bg-lightGrey cursor-pointer`}
                onClick={() => navigateTo(`/prematch/${match.id}`)}
              >
                <div className="flex items-center py-1">
                  <div className="w-full flex justify-between pl-3">
                    <div className="flex items-center">
                      <img
                        src="/img/svg/goal.svg"
                        alt="Competition Logo"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-[#212629] font-fw700">
                        {match.league.name}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="max-w-[400px] text-scoreText mt-1 pr-2 text-textSecondary">
                        {match.time}
                      </p>
                      <p className="max-w-[400px] text-scoreText mt-1 pr-2 text-textSecondary">
                        {formatDate(match.date)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <table className="flex-grow">
                    <tbody>
                      <tr>
                        <td className="flex pl-6 my-2">
                          <TeamFlagBadge flagUrl={match.homeTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.homeTeam.name}
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex pl-6 mb-2">
                          <TeamFlagBadge flagUrl={match.guestTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.guestTeam.name}
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Divider */}
                {index !== pastMatches.length - 1 && (
                  <hr className="border-divider bg-divider " />
                )}
              </div>
            ))
          ) : (
            <div className="text-center text-text14 font-fw500 pb-3">
              <p>Trenutno nema nadolazećih utakmica</p>
            </div>
          )}
        </div>
      )}

      {pastMatches.length > 0 && (
        <div>
          <HeadingTitle title={'Odigrane utakmice'} />
          {pastMatches.length > 0 ? (
            pastMatches.map((match, index) => (
              <div
                key={index}
                className="bg-containerPast cursor-pointer"
                onClick={() => navigateTo(`/match/${match.id}`)}
              >
                <div className="flex items-center">
                  <div className="w-full flex justify-between pl-3 py-1">
                    <div className="flex items-center">
                      <img
                        src="/img/svg/goal.svg"
                        alt="Competition Logo"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-[#212629] font-fw700">
                        {match.league.name}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="max-w-[400px] text-scoreText mt-1 pr-2 text-textSecondary">
                        {match.time}
                      </p>
                      <p className="max-w-[400px] text-scoreText mt-1 pr-2 text-textSecondary">
                        {formatDate(match.date)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <table className="flex-grow">
                    <tbody>
                      <tr>
                        <td className="flex pl-6 my-2">
                          <img
                            src={match.homeTeam.logoUrl}
                            alt={match.homeTeam.name}
                            className="object-cover rounded-full h-[24px] w-[24px]"
                          />
                          <h2 className="pl-4 text-black">
                            {match.homeTeam.name}
                          </h2>
                        </td>
                        <td className="text-right pr-5">
                          <div
                            className={`${
                              match.homeTeam.scored > match.guestTeam.scored
                                ? 'ffont-fw500 text-black'
                                : 'text-textSecondary'
                            }`}
                          >
                            {match.homeTeam.scored}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex pl-6 my-2">
                          <img
                            src={match.guestTeam.logoUrl}
                            alt={match.guestTeam.name}
                            className="object-cover rounded-full h-[24px] w-[24px]"
                          />
                          <h2 className="pl-4 text-black">
                            {match.guestTeam.name}
                          </h2>
                        </td>
                        <td className="text-right pr-5">
                          <div
                            className={`${
                              match.guestTeam.scored > match.homeTeam.scored
                                ? 'font-fw500 text-black'
                                : 'text-textSecondary'
                            }`}
                          >
                            {match.guestTeam.scored}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Divider */}
                {index !== pastMatches.length - 1 && (
                  <hr className="border-divider divider " />
                )}
              </div>
            ))
          ) : (
            <div className="p-2 text-center">
              <p className="text-scoreText font-fw500">
                Trenutno nema odigranih utakmica
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CompetitionMatches;
