import React, { useState, useEffect } from 'react';
import DetailsContent from '../components/prematch/DetailsContent';
// import FormationTab from "../components/prematch/FormationContent";
import { useParams } from 'react-router-dom';
import {
  fetchMatchDetails,
  // fetchMatchEvents,
  getLastFiveH2HMatches,
  getTeamLastFive,
} from '../services/match-service';
// import { fetchMatchProvisionalLineUps } from "../services/lineups-service";
// import { getInjuredSuspended } from "../services/lineups-service";
import Head2Head from '../components/prematch/Head2Head';
import Loader from '../shared/Loader';
// import NoFormationMessage from "../shared/NoFormationMessage";
import { Tabs } from '../shared/Tabs';

export default function PrematchDetails() {
  const [activeTab, setActiveTab] = useState('details');
  const [matchDetails, setMatchDetails] = useState(null);
  // const [matchEvents, setMatchEvents] = useState(null);
  // const [matchFormations, setMatchFormations] = useState(null);
  const { matchId } = useParams();
  const [homeTeamLastFive, setHomeTeamLastFive] = useState([]);
  const [guestTeamLastFive, setGuestTeamLastFive] = useState([]);
  const [lastFiveH2H, setLastFiveH2H] = useState([]);
  const [loading, setLoading] = useState(true);
  const [betRadarMappings, setBetRadarMappings] = useState({});
  // const [injuredSuspendedHome, setInjuredSuspendedHome] = useState([]);
  // const [injuredSuspendedGuest, setInjuredSuspendedGuest] = useState([]);

  // Fetch the BetRadar mappings from the public/config directory
  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch('/config/betRadarIdsMapping.json');
        const data = await response.json();
        setBetRadarMappings(data);
      } catch (error) {
        console.error('Error fetching BetRadar mappings:', error);
      }
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (matchId && Object.keys(betRadarMappings).length > 0) {
      setActiveTab('details');

      // Check if matchId exists in BetRadar ID mapping
      const mappedMatchId = betRadarMappings[matchId] || matchId;

      fetchMatchData(mappedMatchId);
    }
  }, [matchId, betRadarMappings]);

  const fetchMatchData = async (matchId) => {
    try {
      setLoading(true);
      const data = await fetchMatchDetails(matchId);
      // const events = await fetchMatchEvents(matchId);
      // const formations = await fetchMatchProvisionalLineUps(matchId);
      setMatchDetails(data || {});
      // setMatchEvents(events || []);
      // setMatchFormations(formations || []);

      // if (data && data.matches && data.matches.length > 0) {
      //   // Fetch injured/suspended players for both home and guest teams
      //   const homeTeamId = data.matches[0]?.homeTeam.sourceId;
      //   const guestTeamId = data.matches[0]?.guestTeam.sourceId;

      //   if (homeTeamId && guestTeamId) {
      //     const homeInjuredSuspended = await getInjuredSuspended(homeTeamId);
      //     const guestInjuredSuspended = await getInjuredSuspended(guestTeamId);

      //     setInjuredSuspendedHome(homeInjuredSuspended || []);
      //     setInjuredSuspendedGuest(guestInjuredSuspended || []);
      //   }
      // }

      // if (data && data.matches && data.matches.length > 0) {
      //   // Fetch injured/suspended players for both home and guest teams
      //   const homeTeamId = data.matches[0]?.homeTeam.sourceId;
      //   const guestTeamId = data.matches[0]?.guestTeam.sourceId;

      //   if (homeTeamId && guestTeamId) {
      //     const homeInjuredSuspended = await getInjuredSuspended(homeTeamId);
      //     const guestInjuredSuspended = await getInjuredSuspended(guestTeamId);

      //     setInjuredSuspendedHome(homeInjuredSuspended || []);
      //     setInjuredSuspendedGuest(guestInjuredSuspended || []);
      //   }
      // }

      if (data.matches) {
        const homeTeamId = data.matches[0]?.homeTeam.id;
        const guestTeamId = data.matches[0]?.guestTeam.id;

        if (homeTeamId && guestTeamId) {
          const homeTeamLastFive = await getTeamLastFive(homeTeamId);
          const guestTeamLastFive = await getTeamLastFive(guestTeamId);
          const last5H2H = await getLastFiveH2HMatches(homeTeamId, guestTeamId);

          setHomeTeamLastFive(homeTeamLastFive || []);
          setGuestTeamLastFive(guestTeamLastFive || []);
          setLastFiveH2H(last5H2H || []);
        }
      }
      setMatchDetails(data || {});
    } catch (error) {
      console.error('Error fetching match data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    {
      label: 'Detalji',
      isActive: activeTab === 'details',
      onClick: () => handleTabClick('details'),
    },
    {
      label: 'H2H',
      isActive: activeTab === 'h2h',
      onClick: () => handleTabClick('h2h'),
    },
    // {
    //   label: "Postave",
    //   isActive: activeTab === "lineup",
    //   onClick: () => handleTabClick("lineup"),
    // },
  ];

  return (
    <div>
      <div className="p-3 bg-white">
        <Tabs tabs={tabs} />
      </div>
      {!loading ? (
        <>
          {activeTab === 'details' && (
            <DetailsContent
              homeTeamLastFive={homeTeamLastFive}
              guestTeamLastFive={guestTeamLastFive}
              matchDetails={matchDetails}
            />
          )}
          {activeTab === 'h2h' && (
            <Head2Head
              matchDetails={matchDetails}
              matchId={matchId}
              homeTeamLastFive={homeTeamLastFive}
              guestTeamLastFive={guestTeamLastFive}
              lastFiveH2HMatches={lastFiveH2H}
            />
          )}
          {/* {activeTab === "lineup" && (
            <>
              {Object.keys(matchFormations).length > 0 &&
              matchFormations.guestTeam.length > 0 &&
              matchFormations.guestTeamLineup.length > 0 &&
              matchFormations.homeTeam.length > 0 &&
              matchFormations.homeTeamLineup.length > 0 ? (
                <FormationTab
                  matchDetails={matchDetails}
                  matchEvents={matchEvents}
                  matchFormation={matchFormations}
                  injuredSuspendedHome={injuredSuspendedHome}
                  injuredSuspendedGuest={injuredSuspendedGuest}
                />

              ) : (
                // Render NoFormationMessage if matchFormations are empty
                <NoFormationMessage />
              )}
            </>
          )} */}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
